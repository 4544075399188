import React from "react";

const DrivosityCertifiedData = [
    {
        id: 8,
        title: `DrivosityCERTIFIED`,
        content: 'Recognizing those who keep our communities safe',
        location: `/drivosity-certified`,
        data: null,
    },
    {
        id: 8,
        title: `DRIVOSITY CERTIFIED`,
        content: (
            <>
                <div className='mb-7'>
                    Is a celebration of community. Through Drivosity, there are an increasing amount of safe drivers right outside our front doors, and that is something we are proud to be a part of.
                </div>
                <div className='mb-7'>
                    We wanted to recognize the hardworking drivers, managers, and businesses who push to keep their communities safe. Drivers are at the heart of Drivosity, and through our in-house certification system, Certified Safe Drivers are eligible for exclusive perks, letting the world know that they are part of the solution in keeping our roads safe for all.
                </div>
                <div className='mb-7'>
                    Driving habits impact lives, and Drivosity thanks those for their dedication to safer roads for all as being recognized by a DrivosityCertified Safe Driver.
                </div>
            </>
        ),
        location: `/drivosity-certified`,
        data: null,
    },
];

export {DrivosityCertifiedData};
