import React from "react";
import {navigate} from "gatsby";

import { NewsCardContent, NewsCardImg, NewsCardContainer, NewsCardDescription, NewsCardTitle, NewsCardDate, NewsImage } from './DrvNewsCard.styles';

const DrvNewsCard = ({item, index}) => {
    return(
        <NewsCardContainer onClick={() => {
            if (item.externalLink) {
                window.open(item.externalLink, '_blank').focus();
            } else {
                navigate(`/news-details/?id=${index}`)
            }
        }} className="container flex p-0 flex-col lg:flex-row" key={index}>
            <NewsCardImg className="">
                <NewsImage src={item.data.image} />
                {
                    item.data.date && (
                        <NewsCardDate>
                            <div className="text-2xl font-semibold">
                                {
                                    new Date(item.data.date).toLocaleString("en-US", { day : '2-digit', timeZone: 'UTC' })
                                }
                            </div>
                            <div>
                                {
                                    new Date (item.data.date).toLocaleString('default', { month: 'short', timeZone: 'UTC' })
                                }
                            </div>
                        </NewsCardDate>
                    )
                }
            </NewsCardImg>
            <NewsCardContent>
                <NewsCardTitle>
                    {item.title}
                </NewsCardTitle>
                <NewsCardDescription>
                    {item.data.description}
                </NewsCardDescription>
            </NewsCardContent>
        </NewsCardContainer>
    )
}

export default DrvNewsCard;
