import React, {useState, useEffect, useCallback, useMemo } from "react";
import {navigate} from "gatsby";
import * as SearchLibrary from "js-search";
import {NumberParam, StringParam, useQueryParam} from "use-query-params";

import DrvCover from "../Shared/DrvCover/DrvCover";
import {DrvLinkPath} from "../Shared/DrvLink/DrvLinkPath";
import {DrvInput} from "../Shared/DrvInput/DrvInput";

import {SearchData} from "./Search.data";
import {HomeData, HomeSliderData, HomeCommentsData} from "../Home/Home.data";
import {SafetyData} from "../Solutions/Safety/Safety.data";
import {ProductivityData} from "../Solutions/Productivity/Productivity.data";
import {FinancingData} from "../Solutions/Financing/Financing.data";
import {AboutUsData} from "../OurCompany/AboutUs/AboutUs.data";
import {ForBusinessData, ForBusinessBenefitsCollapse, ForBusinessComponentsCollapse} from "../OurCompany/ForBusiness/ForBusiness.data";
import {ForDriversData, ForDriversCollapseData, ForDriversSliderData, ForDriversTabCollapseData} from "../OurCompany/ForDrivers/ForDrivers.data";
import {DrivosityHTHData} from "../OurCompany/DrivosityHTH/DrivosityHTH.data";
import {DrivosityCertifiedData} from "../OurCompany/DrivosityCertified/DrivosityCertified.data";
import {NewsData} from "../OurCompany/News/News.data";
import {ContactUsData} from "../OurCompany/ContactUs/ContactUs.data";
import {CareersData, JobsData} from "../Careers/Careers.data";
import {CustomerStoriesData} from "../CustomerStories/CustomerStories.data";
import {RequestDemoData} from "../RequestDemo/RequestDemo.data";
import {TermsOfUseData} from "../TermsOfUse/TermsOfUse.data";
import {PrivacyPolicyData} from "../PrivacyPolicy/PrivacyPolicy.data";

import {Result, Title} from "./Search.styles";
import './Search.css';

import Cover from '../../images/Search/Cover.jpeg';
import DrvNewsCard from "../Shared/DrvNewsCard/DrvNewsCard";
import Pagination from "../Shared/DrvPagination/DrvPagination";

const Search = () => {
    const [searchText] = useQueryParam('s', StringParam);
    const [searchPage] = useQueryParam('p', NumberParam);
    const [state, setState] = useState({
        data: [],
        search: [],
        searchQuery: searchText,
        searchResults: [],
        displayedResults: [],
        currentPage: searchPage,
        isLoading: true,
        isError: false,
    });

    useEffect(() => {
        let docs = [];
        let data = [];
        data = data.concat(
            HomeData,
            HomeCommentsData,
            HomeSliderData,
            SafetyData,
            ProductivityData,
            FinancingData,
            AboutUsData,
            ForBusinessData,
            ForBusinessBenefitsCollapse,
            ForBusinessComponentsCollapse,
            ForDriversData,
            ForDriversCollapseData,
            ForDriversSliderData,
            ForDriversTabCollapseData,
            DrivosityHTHData,
            DrivosityCertifiedData,
            NewsData,
            ContactUsData,
            CareersData,
            JobsData,
            CustomerStoriesData,
            RequestDemoData,
            TermsOfUseData,
            PrivacyPolicyData
        );

        for (let i = 0; i < data.length; i++){
            if(typeof data[i].content !== "string") {
                let dataToPush = {
                    id: data[i].id,
                    title: data[i].title,
                    location: data[i].location,
                    data: data[i].data,
                    content: ''
                }

                let contentArr = data[i].content.props.children;
                for (let j = 0; j < contentArr.length; j++) {
                    if (typeof contentArr[j] !== "string") {
                        dataToPush.content += contentArr[j].props.children;
                    } else {
                        dataToPush.content += contentArr[j];
                    }
                }

                docs.push(dataToPush);
            }else{
                docs.push(data[i]);
            }
        }

        setState(previous => {
            return {
                ...previous,
                data: docs
            }
        })
    }, []);

    useEffect(() => {
        rebuildIndex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.data]);

    const rebuildIndex = useCallback(() => {
        const {data} = state;
        const dataToSearch = new SearchLibrary.Search("id");

        dataToSearch.indexStrategy = new SearchLibrary.PrefixIndexStrategy();
        dataToSearch.sanitizer = new SearchLibrary.LowerCaseSanitizer();
        dataToSearch.searchIndex = new SearchLibrary.TfIdfSearchIndex("id");
        dataToSearch.addIndex("title");
        dataToSearch.addIndex("content");
        dataToSearch.addDocuments(data);

        setState(previous => {
            return {
                ...previous,
                search: dataToSearch,
                isLoading: false
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.data, setState]);

    /**
     * Handles the input change and perform a search with js-search
     * in which the results will be added to the state
     */
    const searchData = useCallback((text, page) => {
        const {search} = state;
        const queryResult = search.search(text);

        setState(previous => {
            return {
                ...previous,
                currentPage: page,
                searchQuery: text,
                searchResults: queryResult,
                displayedResults: queryResult.slice((previous.currentPage - 1) * 10, previous.currentPage * 10)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search, setState]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
    }, []);

    /**
     * Input change and submit event
     **/
    const inputChange = useCallback((e) => {
        if (e.keyCode === 13){
            navigate(`/search?s=${e.target.value}&p=${1}`);
        } else {
            setState(previous => {
                return {
                    ...previous,
                    searchQuery: e.target.value
                }
            })
        }
    }, []);

    /**
     * Pagination functions page, next and previous...
     **/

    const paginate = useCallback((page) =>{
        setState(previous => {
            return {
                ...previous,
                currentPage: page
            }
        });
    }, [setState]);

    const prevPage = useCallback(() =>{
        setState(previous => {
            return {
                ...previous,
                currentPage: previous.currentPage - 1
            }
        });
    }, [setState]);

    const nextPage = useCallback(() =>{
        setState(previous => {
            return {
                ...previous,
                currentPage: previous.currentPage + 1
            }
        });
    }, [setState]);

    /**
     * Make search on init of search page based on query param text sent by navbar
     **/
    useMemo(() => {
        if (!state.isLoading) {
            searchData(searchText, searchPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search]);

    /**
    * Make search when query param text is changed
    **/
    useMemo(() => {
        if (!state.isLoading) {
            searchData(searchText, searchPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    /**
     * Scroll to top and re-filter results on page change
     **/
    useMemo(() => {
        if (!state.isLoading) {
            if(typeof window !== 'undefined'){
                window.scrollTo(0, 100)
            };

            setState(previous => {
                return {
                    ...previous,
                    displayedResults: previous.searchResults.slice((previous.currentPage - 1) * 10, previous.currentPage * 10)
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentPage]);

    /**
     * Change query params when pagination is changed
     **/
    useMemo(() => {
        if (!state.isLoading) {
            navigate(`/search?s=${state.searchQuery}&p=${state.currentPage}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentPage]);

    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title='SEARCH RESULTS'
                description=''
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className='container mx-auto' style={{paddingTop: '55px'}}>
                <DrvLinkPath items={[{ text: 'Drivosity', link: '/', isLink: true }, { text: `Search results for '${searchText}'`, link: '/search', isLink: false }]} />
            </div>

            {/*CONTENT*/}
            <div className='py-12 container mx-auto search-container'>
                <div className='drv-subtitle'>
                    NEED A NEW SEARCH?
                </div>

                <div className='drv-description mb-7' style={{color: 'var(--text-color)'}}>
                    If you didn't find what you were looking for, try a new search!
                </div>

                <div className='mb-7'>
                    <form onSubmit={handleSubmit}>
                        <DrvInput placeholder='Search...' id="search" value={state.searchQuery} onChange={inputChange} onKeyUp={inputChange}/>
                    </form>
                </div>

                <div>
                    {
                        state.displayedResults.length === 0 ? (
                            <div className='drv-description mb-7' style={{color: 'var(--text-color)'}}>
                                Sorry, but nothing matched your search terms. Please try again with some different keywords.
                            </div>
                        ) : (
                            <>
                                {
                                    state.displayedResults.map(item => {
                                        if (item.location === '/news') {
                                            return (
                                                <DrvNewsCard key={item.id} item={NewsData[item.data.id]} index={item.data.id} />
                                            )
                                        } else {
                                            return (
                                                <Result key={item.id}>
                                                    <Title to={item.location}>{SearchData[item.location].title}</Title>
                                                    <div>
                                                        {SearchData[item.location].content}
                                                    </div>
                                                </Result>
                                            )
                                        }
                                    })
                                }

                                <Pagination
                                    totalPosts={state.searchResults.length}
                                    postsPerPage={10}
                                    paginate={paginate}
                                    currentPage={state.currentPage}
                                    pervPage={prevPage}
                                    nextPage={nextPage}
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Search;
