import React from 'react';
import { PaginationRow, PaginationNumber, PaginationPrev,  PaginationNext} from './DrvPagination.styles';

import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi'

const Pagination = ({postsPerPage, totalPosts, paginate, currentPage, pervPage, nextPage}) => {
    const pageNumbers = [];
    for(let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++){
        pageNumbers.push(i)
    }
    return(
        <PaginationRow>
            {currentPage !== 1 && <PaginationPrev onClick={() => pervPage()}><BiLeftArrowAlt style={{height: '24px', width: '24px'}}></BiLeftArrowAlt> Prev</PaginationPrev>}
            {
                pageNumbers.map((number) =>(
                    <PaginationNumber className={currentPage === number ? 'active' : ''} key={number} onClick={() =>paginate(number)}>
                        {number}
                    </PaginationNumber>
                ))
            }
            {currentPage !== pageNumbers[pageNumbers.length-1]&&
            <PaginationNext onClick={() => nextPage()}>Next<BiRightArrowAlt style={{height: '24px', width: '24px'}}></BiRightArrowAlt></PaginationNext>
            }
        </PaginationRow>
        
    )
}

export default Pagination;
