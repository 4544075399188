import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Search from "../components/Search/Search";

import Favicon from "../images/Favicon.png";

const SearchPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Search - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
            </Helmet>
            <Layout component={<Search />} />
        </>
    )
}

export default SearchPage;
