import * as React from "react";

const ProductivityData = [
    {
        id: 2,
        title: `DrivosityEDGE`,
        content: (
            <>
                Give your business a whole new edge. Maximize your operations with
                DrivosityEDGE, adding enhanced productivity, customer feedback, and
                driver engagement to your safety solution.
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `ENHANCE PRODUCTIVITY WITH MORE TRANSPARENT DELIVERIES`,
        content: (
            <>
                <div className='paragraph'>
                    Give your business a productivity upgrade, adding transparency into the first-party delivery experience. Inspire your drivers’ HustleTime™ with comprehensive metrics including:
                </div>

                <div className="points">Customer Wait Time</div>
                <div className="points">Guest Feedback</div>
                <div className="points">Service Measures</div>

                <div className='paragraph' style={{fontWeight: 500, marginTop: '18px'}}>
                    “If you can’t measure it, you can’t improve it.” –Peter Drucker
                </div>

                <div className='paragraph'>
                    With EDGE, Drivosity goes beyond safety, delivering great drivers in a whole new way. As a result of EDGE, you can expect the following out of your business:
                </div>

                <div className="points">Less Out of Scope Activities by Drivers</div>
                <div className="points">Up to an 18% Reduction in Extended Stops</div>
                <div className="points">Increased Deliveries per Hour</div>
                <div className="points">Faster Service Times</div>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        content: (
            <div className='paragraph'>
                Our reporting options are flexible and can be customized to show statistics for drivers that provide insight into productivity and efficiency. Reports can be run for custom time periods and are sortable by category. Each report can be exported as an Excel file or printed.
            </div>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">Trip Review</span>
                <span className="flex justify-center text-center text-small font-extralight">Full transparency into the delivery experience.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">Incident Report</span>
                <span className="flex justify-center text-center text-small font-extralight">Show where you excel and where you can improve productivity.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">Efficiency</span>
                <span className="flex justify-center text-center text-small font-extralight">Displayed labor statistics to manage your business.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">DriveScore&reg;</span>
                <span className="flex justify-center text-center text-small font-extralight">Never lose sight of your safety standards.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `TO-THE-DOOR-TRACKING`,
        content: (
            <>
                <div className='paragraph'>
                    Tracking customer wait time and your drivers’ HustleScore™, DrivosityEDGE provides unique statistics that provide insight into delivery operations.
                </div>
                <div className="points">
                    Out-The-Door-Time: The time between when an order is placed and when it’s assigned to a driver
                </div>
                <div className="points">
                    Seatbelt Time: The time between when the order is assigned to a driver and when that driver leaves the store’s geofence
                </div>
                <div className="points">
                    At-The-Door-Time: The time a driver spends at their delivery location
                </div>
                <div className="points">
                    Lot-Time: The time from when the driver returns back to the geofence to when they check in to confirm delivery completion
                </div>
                <div className="points">
                    Insight into Single and Double delivery percentages
                </div>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `INCREASE CUSTOMER ENGAGEMENT`,
        content: (
            <>
                <div className='paragraph'>
                    DrivosityEDGE  improves management of deliveries and allows customer feedback visible on your Driver Inspire Display. EDGE also provides added layers of operational efficiency resulting in:
                </div>
                <div className="points">
                    Food arriving on time and in optimal condition for consumption
                </div>
                <div className="points">
                    Increased driver engagement to provide stellar customer service
                </div>
                <div className="points">
                    Real-time feedback of customer satisfaction ratings
                </div>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: ``,
        content: (
            <>
                Want to learn more about DrivosityEDGE?
            </>
        ),
        location: `/productivity`,
        data: null,
    },
];

export {ProductivityData};
