import * as React from "react";

import ToTheDoor  from '../../images/Home/TaberTTD.jpg';
import Replay from '../../images/Home/TaberReplay.jpg';
import Certified from '../../images/Home/TaberCertified.jpg';
import Trips from '../../images/Home/TaberTrip.jpg';

const HomeData = [
    {
        id: 0,
        title: `A COMPLETE SOLUTION FOR YOUR DELIVERY NEEDS`,
        content: (
            <>
                Created as a combined result of expertise in the last-mile delivery and telematics industries,
                Drivosity’s solutions increase productivity, inspire safer driving and reduce operating
                costs related to your food delivery business.
                <br/><br/>
                We empower businesses with the transparency into the complete restaurant delivery experience,
                including <span className='strong'>real-time driver tracking, a proven behavior-modifying scoring system</span>,
                and a <span className='strong'>breakdown of productivity metrics</span> geared to drive your business to new levels
                of profitability and efficiency.
            </>
        ),
        location: `/`,
        data: null,
    },
    {
        id: 0,
        title: `Ready for Drivosity?`,
        content: (
            <>
                Our approach to GPS tracking, driver empowerment and customer engagement accelerates the continued growth and operational excellence for some of the most successful brands in the food delivery industry. Contact us at sales@drivosity.com or click below to learn more.
            </>
        ),
        location: `/`,
        data: null,
    },
    {
        id: 0,
        title: ``,
        content: `
            TRACK YOUR DELIVERY FORCE IN REAL-TIME,
            Drivers,
            Drivosity Trips,
            Miles Driven,
            Miles Driven Weekly,
            FEATURED CUSTOMERS,
            FEATURED PARTNERS
        `,
        location: `/`,
        data: null,
    },
];

const HomeSliderData = [
    {
        id: 0,
        title: `Driver Inspired Platform`,
        content: 'Increase safety and productivity through transparency of driving habits.',
        location: `/`,
        data: {
            image: Trips
        }
    },
    {
        id: 0,
        title: `Review Your Trips`,
        content: 'Easy to review trips for coaching and driver management.',
        location: `/`,
        data: {
            image: Replay
        }
    },
    {
        id: 0,
        title: `DrivosityCertified`,
        content: 'Certification and recognition of the safest drivers on the road.',
        location: `/`,
        data: {
            image: Certified
        }
    },
    {
        id: 0,
        title: `Customer Wait Time`,
        content: 'Analytical tools to break down the complete delivery experience, boosting productivity and adding to the bottom line.',
        location: `/`,
        data: {
            image: ToTheDoor
        }
    },
];

const HomeCommentsData = [
    {
        id: 0,
        title: ``,
        content: 'Drivosity is a game changer when it comes to safety awareness and accountability to safe driving. Since installing Drivosity, I have had way less incidents regarding driver safety because I am aware of driving habits and can address issues immediately. This system makes our company a better place to work, while providing data to increase productivity. Insurance costs have also decreased by more than 30% after only 2 years with Drivosity.',
        data: {
            name: 'Charles Peters',
            position: 'Operating Partner'
        },
        location: `/`
    },
    {
        id: 0,
        title: ``,
        content: 'I’m always searching for the best technologies that make a big impact. Drivosity has certainly exceeded our expectations. Their customer service is outstanding. Always a quick response. Drivosity has also had a huge positive effect on our businesses; from decreasing service times and increasing driver safety to improving delivery times. I recommend Drivosity to everyone in the industry!',
        data: {
            name: 'Sammy Mandell',
            position: 'Restaurant Owner/Entrepreneur'
        },
        location: `/`
    },
    {
        id: 0,
        title: ``,
        content: 'Drivosity’s mission is to save lives by creating better drivers.  They do that.  They also provide me with easy-to-read, transparent metrics that allow me to see in real time how my drivers are performing and how well they are abiding by our safety standards.  We expected this when we signed up for Drivosity.  What we did not expect was that their services would increase our productivity, optimize our workflow, and elevate our customer satisfaction scores.  I have been in this industry for almost twenty years and I can count on one hand the number of innovations that have fundamentally transformed our business.  Drivosity is one of those innovations.',
        data: {
            name: 'Brian Krasielwicz',
            position: 'Director of Operations'
        },
        location: `/`
    },
    {
        id: 0,
        title: ``,
        content: 'I can’t imagine being in a delivery business without Drivosity. With Drivosity, we are able to dispatch deliveries efficiently while focusing on our customers as a top priority. Safe driving is measured along with actual drive times, creating a positive behavior among delivery drivers. Love It!',
        data: {
            name: 'Wendy Longo',
            position: 'General Manager'
        },
        location: `/`
    }
];

export {HomeData, HomeSliderData, HomeCommentsData};
