import * as React from "react";

const SafetyData = [
    {
        id: 1,
        title: `DrivosityCORE`,
        content: (
            <>
                We believe that safety should be accessible to all. With DrivosityCORE, your drivers are empowered with the knowledge of their driving habits, bringing safe driving where it matters most.
            </>
        ),
        location: `/safety`,
        data: null,
    },
    {
        id: 1,
        title: ``,
        content: (
            <>
                Safety doesn’t happen by accident. It’s the result of a conscious effort and a commitment to excellence. Drivosity provides the tools necessary to strive for that excellence.
            </>
        ),
        location: `/safety`,
        data: {
            name: 'SCOTT TANIS',
            position: 'Vice President of Operations'
        },
    },
    {
        id: 1,
        title: `INSPIRE SAFER DRIVING BEHAVIORS`,
        content: (
            <>
                According to a 2009 study by the NHTSA [1], unsafe drivers are about twice as likely to brake harshly, turn their vehicles at greater than 0.30 g and drive and accelerate at inappropriate speeds. These unsafe drivers also have a higher chance of being involved in a car accident. At Drivosity, we track and measure these risk factors to help clients reduce accidents and claims by rewarding and incentivizing safe driving behaviors.  Our Car Toppers solution encourages drivers to self-correct, while providing GPS data that leads to peace of mind and an improved bottom line.
                <br/><br/>
                Want to learn more about making your drivers safer while saving more on insurance? Schedule a free demonstration today.
            </>
        ),
        location: `/safety`,
        data: null
    },
    {
        id: 1,
        title: `DRIVESCORE`,
        content: (
            <>
                What is safe driving? DriveScore&reg; is an objective, quantitative method that measures driver safety. Drivosity’s scoring system empowers drivers and their managers with detailed information on how they are performing. Our DriveScore&reg; algorithm is based on behaviors identified by the NHTSA as increasing the risk of an accident:
                <br/><br/>
                <div className='py-3 font-medium' style={{color: 'var(--bold-text-color)'}}>Speeding</div>
                <div className='py-3 font-medium' style={{color: 'var(--bold-text-color)'}}>Hard Braking</div>
                <div className='py-3 font-medium' style={{color: 'var(--bold-text-color)'}}>Hard Acceleration</div>
                <div className='py-3 font-medium' style={{color: 'var(--bold-text-color)'}}>Hard Cornering</div>
            </>
        ),
        location: `/safety`,
        data: null
    },
    {
        id: 1,
        title: `MOST ACCURATE SPEED LIMITS IN THE INDUSTRY`,
        content: (
            <>
                Every speed limit on every road of customer delivery areas are validated with a unique speed limit validation process. Drivosity empowers businesses and franchises to objectively evaluate and incentivize performance with confidence that DriveScores®  are based on the most accurate speed limit data in last mile delivery.
            </>
        ),
        location: `/safety`,
        data: null
    },
    {
        id: 1,
        title: ``,
        content: (
            <>
                Want to learn more about DrivosityEDGE?
            </>
        ),
        location: `/safety`,
        data: null
    },
];


export {SafetyData};
