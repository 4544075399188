import styled from "styled-components";
import {Link} from "gatsby";

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  margin-bottom: 30px;
  font-size: 13px;
  border-radius: 1px;
  border: 1px solid #e6e6e6;
  transition: all 0.5s ease;
  color: var(--text-color);
  
  &:hover {
    box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);
    border-color: transparent;
  }
`;

export const Title = styled(Link)`
  font-size: 19px;
  margin-bottom: 15px;
  color: var(--subtitle-color);
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
`
