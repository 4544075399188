const ContactUsData = [
    {
        id: 9,
        title: `GET IN TOUCH`,
        content: 'If you have any questions about Drivosity or how we can support your business, please complete and submit our contact form.',
        location: `/contact-us`,
        data: null,
    },
    {
        id: 9,
        title: ``,
        content: `
            CONTACT US,
            SALES,
            GENERAL INQUIRIES,
            BILLING INQUIRIES,
            TECH SUPPORT,
            UNITED STATES HEADQUARTERS,
            UNITED STATES PRODUCTION FACILITY,
            NORTH MACEDONIA,
            SERBIA
        `,
        location: `/contact-us`,
        data: null,
    }
];

export {ContactUsData};
