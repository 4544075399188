import * as React from "react";
import {Image, Points} from "./Financing.styles";

import PEAC from "../../../images/Solutions/Financing/PEAC.png";

const FinancingData = [
    {
        id: 3,
        title: `YOU CAN’T AFFORD TO WAIT FOR NEW EQUIPMENT. NOW YOU DON’T HAVE TO.`,
        content: (
            <>
                At Drivosity we have partnered with our financing partner PEAC to make getting Drivosity even easier! With competitive rates for financing, the road to safer driving has become even easier.
            </>
        ),
        location: `/financing`,
        data: null,
    },
    {
        id: 3,
        title: ``,
        content: (
            <>
                <Image src={PEAC}/>
                <div className='paragraph'>
                    PEAC Solutions is the largest global independent equipment financing provider. This impressive
                    footprint gives us the ability to stay on the leading edge of the financial services industry.
                </div>
                <div className='paragraph'>
                    Our multi-national presence gives you access to a wide set of opportunities to expand existing
                    operations and pursue customers worldwide. PEAC offers a comprehensive set of vendor, manufacturer
                    and distributor financing solutions to increase your revenue and margins.
                </div>
                <div className='paragraph'>
                    With our flexible financing solutions and quick approval times, we provide the financing solutions that keep your sales and your business moving forward.
                </div>
            </>
        ),
        location: `/financing`,
        data: null,
    },
    {
        id: 3,
        title: ``,
        content: (
            <>
                <div className='title'>
                    WHY FINANCE WITH PEAC SOLUTIONS:
                </div>
                <div className='points'>
                    100% Financing – Unlike some loan programs, you can finance 100% of your equipment.
                </div>
                <div className='points'>
                    Flexibility – As your business grows and needs change, you will be able to upgrade equipment at any
                    point during the financing term.
                </div>
                <div className='points'>
                    Fast Processing – Most applications are processed within 2 hours.
                </div>
                <div className='points'>
                    Improved Cash Flow – With no hefty down payments and affordable monthly payments, you can keep your
                    cash flow going.
                </div>
                <div className='points'>
                    Flexible Terms – PEAC Solutions offers flexible terms that can fit your needs.
                </div>
                <div className='points'>
                    Frees up Lines of Credit – Save your bank lines of credit for other emergencies.
                </div>
                <div className='points'>
                    Tax Benefits – You may qualify to take advantage of generous IRS tax savings and realize savings
                    that are greater than what you pay in the first year of your contract.
                </div>
                <div className='points'>
                    Fixed Monthly Payments – More accurately forecast spending.
                </div>
            </>
        ),
        location: `/financing`,
        data: null,
    },
    {
        id: 3,
        title: ``,
        content: (
            <>
                <div className='title'>
                    5 STEPS TO PREPARE YOUR BUSINESS FOR SUCCESS
                </div>
                <div className='points'>
                    1.&nbsp;&nbsp;Shop – Shop online or in-person to find the equipment or software solution you need for your business
                </div>
                <div className='points'>
                    2.&nbsp;&nbsp;Apply – Click “apply today” on this page to complete a simple online credit application
                </div>
                <div className='points'>
                    3.&nbsp;&nbsp;Sign – Once approved, sign the finance agreement
                </div>
                <div className='points'>
                    4.&nbsp;&nbsp;Receive – Receive your brand-new equipment
                </div>
                <div className='points'>
                    5.&nbsp;&nbsp;Pay – Make affordable monthly payments
                </div>
            </>
        ),
        location: `/financing`,
        data: null,
    },
    {
        id: 3,
        title: `PEAC`,
        content: (
            <>
                <Points>Fixed rates as low as 6.49%*</Points>
                <Points>36-month term</Points>
                <Points>No points or application fees</Points>
                <Points>No liens on personal assets</Points>
                <Points>Application only up to $300,000</Points>
                <Points>Subject to credit approval</Points>
                <br/>
                <div>
                    *subject to credit approval; restrictions may apply;  solution, program, and package details subject to change or cancellation without notice. Intended for US companies in business 2 years or longer.
                </div>
            </>
        ),
        location: `/financing`,
        data: {
            subtitle: 'PROGRAM DETAILS'
        },
    },
    {
        id: 3,
        title: `DRIVOSITY`,
        content: (
            <>
                <Points>Drivosity software subscription</Points>
                <Points>HTH toppers enabled with Drivosity GPS</Points>
                <Points>Charging wall mount for damage topper storage and recharging</Points>
                <Points>Driver Inspire Google Chromebox</Points>
                <Points>55” 4K Driver Inspire Display with mount and wiring kit</Points>
                <Points>Display monitor warranty</Points>
                <Points>Drivosity Data Subscription</Points>
                <Points>Best Buy Geek Squad&reg; installation services</Points>
                <Points>Trade Map speed limit validation service</Points>
                <Points>Drivosity Care: full equipment warranty and 18 mos. battery replacement service (optional)</Points>
            </>
        ),
        location: `/financing`,
        data: {
            subtitle: 'INCLUDED IN THE PACKAGE'
        },
    },
    {
        id: 3,
        title: ``,
        content: `
            FAQ SECTION,
            PROGRAM DETAILS,
            INCLUDED IN THE PACKAGE
        `,
        location: `/financing`,
        data: null,
    },
];

const FinancingCollapseData = [
    {
        id: 3,
        title: `Does Drivosity provide internal financing?`,
        content: (
            <>
                At Drivosity, we think everyone should have access to our cutting-edge safety and productivity platform, no matter what your financial situation. That is why we have approved PEAC to offer competitive leasing and financing options.
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `How does the loan process work?`,
        content: (
            <>
                It's simple!  Just click on the <a href="https://apply.marlincapitalsolutions.com/auth/EF?partnerid=40798619BVBEAAW" className='drv-link' target="_blank" rel="noopener noreferrer">one-page link</a> to start the process.
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `Does it hit personal credit?`,
        content: (
            <>
                An inquiry on personal credit is part of the approval process.  However, once approved and funded, PEAC's business loan will not show as a trade line on the Borrower's personal credit.
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `How long is my typical loan?`,
        content: (
            <>
                The standard loan with Drivosity is 36 months (3 years).
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `If I get rid of Drivosity can I cancel it?`,
        content: (
            <>
                No – this is not a trial or rental contract.  The Borrower is responsible for the sum of all payments for the entire term of the finance contract.
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `Do I need a Personal Guarantee?`,
        content: (
            <>
                A personal guarantee is required unless the underlying entity has established satisfactory business credit history, subject to approval by PEAC Credit.
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `Does it show as a debt/lien on my business?`,
        content: (
            <>
                PEAC reserves the right to file a Lien as evidence of our Security Interest in the financed amount / loan with the Borrower.
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `Can I add additional equipment mid-term on the agreement?`,
        content: (
            <>
                Assuming your business qualifies, the Borrower can finance additional equipment, which may be added to the original Agreement depending on the timing of the addition (typically within 90 days and amounts above $5,000).
            </>
        ),
        location: `/financing`,
    },
    {
        id: 3,
        title: `Are there deferred payment options?`,
        content: (
            <>
                Yes!  Assuming your business qualifies, the Borrower can defer the first payment for up to 90 days (3 months) from closing the loan on Drivosity's solution.
            </>
        ),
        location: `/financing`,
    },
];

export {FinancingData, FinancingCollapseData};
