import React from 'react'

const CareersData = [
    {
        id: 10,
        title: `BECOME A PART OF THE SOLUTION`,
        content: 'Since 2015, Drivosity has expanded as a global company in both its team and its customers. To join a team of results-driven winners, apply here',
        location: `/careers`,
        data: null,
    },
    {
        id: 10,
        title: `BECOME A PART OF THE SOLUTION`,
        content: (
            <>
                It does feel good to be recognized for your hard work, but I didn’t really expect any special praise or acknowledgment for doing what I felt I should be doing.
                Yet, for someone to notice—and to think you can leave that kind of impression—it’s an added incentive to always do your best because you just never know who’s watching and how it might turn out others life.
                I fully trusted this company’s mission that i shaped my entire life surrounding with the job. We both grow simultaneously.
                We have good days and bad days, just like any other industry. But when I’m able to positively influence the driving habits and live safer, that makes it all worth it.
                Its been one of the greatest decision for my career life.
            </>
        ),
        location: `/careers`,
        data: {
            name: 'MENSUR JUSUFI',
            position: 'CLOUD SYSTEM ENGINEER'
        },
    },
    {
        id: 10,
        title: `ABOUT DRIVOSITY`,
        content: (
            <>
                Since 2015, Drivosity has expanded as a global company in both its team and its customers. To join a team of results-driven winners, apply here
                <br/><br/>
                Our team leverages decades of combined experience in technology and delivery to provide solutions to manage safety while bettering customer service times, operational transparency, driver engagement, as well as team productivity. By investing in ourselves and our team, we have been able to grow to be the leading safety and productivity solution for the largest global players in last mile delivery.
                <br/><br/>
                Drivosity’s culture of success through empowerment, both with our customers and with team members, has fostered extraordinary growth and an exciting, vibrant, fast-paced work environment where everyone makes a difference and saves lives.
            </>
        ),
        location: `/careers`,
        data: null
    },
    {
        id: 10,
        title: ``,
        content: 'Do you want to work with us?',
        location: `/careers`,
        data: null
    },
];

const JobsData = [
    // {
    //     id: 101,
    //     title: `Business Analyst`,
    //     content: (
    //         <>
    //             <div className="paragraph">Drivosity is looking for a Business Analyst and the incumbent will be responsible for:</div>
    //             <div className="paragraph-list">Familiarity with the Drivosity Business Model, Platform, Market and Customers</div>
    //             <div className="paragraph-list">Development of requirement documentation that will translate business needs into requirements that can be understood by the solution development team </div>
    //             <div className="paragraph-list">Staying current with customer needs utilizing formal and informal written communication methods to communicate updates and findings</div>
    //             <div className="paragraph">In this role, you should be detail-oriented and possess excellent communication skills, both written and verbal. Ultimately, you will ensure that verbalized business requirements are committed to paper and stay aligned to customer needs.</div>
    //             <div className="drv-title pt-8">Responsibilities</div>
    //             <div className="paragraph-list">Evaluate business processes, anticipate requirements, uncover areas for improvement, and develop solutions</div>
    //             <div className="paragraph-list">Collaborate with stakeholders during the visioning and concept development of a product</div>
    //             <div className="paragraph-list">Develop scope and define backlog items (epic/features/user stories) that guide the Agile software development team</div>
    //             <div className="paragraph-list">Possess a fundamental understanding of end-to-end customer experience integration and dependencies</div>
    //             <div className="paragraph-list">Serve as the voice of the client</div>
    //             <div className="paragraph-list">Work with internal and external customers to analyze product needs and align to product roadmap</div>
    //             <div className="paragraph-list">Participate as needed in IT design reviews to ensure alignment to business requirements</div>
    //             <div className="paragraph-list">Manage special projects as assigned by develop leads</div>
    //             <div className="paragraph-list">Provide advice and consultation to other more junior Business Analysts on more difficult and complex assignments and implement ways to reuse requirements across projects</div>
    //             <div className="paragraph-list">Ensure that proposed system features and requirements meet user needs and satisfy business objectives and initiatives</div>
    //             <div className="paragraph-list">Assist in creation and implementation of test plans that are mapped to documented business requirements</div>
    //             <div className="paragraph-list">Assist in creation of User Acceptance Testing (UAT) documentation</div>
    //             <div className="paragraph-list">Assist in creation of training and system documentation</div>
    //             <div className="drv-title pt-8">Requirements</div>
    //             <div className="paragraph-list">A bachelor’s degree in computer science, business or related field or an MBA</div>
    //             <div className="paragraph-list">A minimum of 5 years’ experience in business analysis or a related field</div>
    //             <div className="paragraph-list">Must have experience working on large scale projects as well as handling day-to-day business requests from customers</div>
    //             <div className="paragraph-list">Strong project management, ability to successfully manage multiple tasks at any given point, strong relationship building skills & communication skills</div>
    //             <div className="paragraph-list">Understanding of Indices, fundamentals and analytical data, returns level data etc</div>
    //             <div className="paragraph-list">Exceptional analytical and conceptual thinking skills</div>
    //             <div className="paragraph-list">The ability to influence stakeholders and work closely with them to determine acceptable solutions</div>
    //             <div className="paragraph-list">Excellent documentation skills</div>
    //             <div className="paragraph-list">Fundamental analytical and conceptual thinking skills</div>
    //             <div className="paragraph-list">Experience creating detailed reports and giving presentations</div>
    //             <div className="paragraph-list">A track record of following through on commitments</div>
    //             <div className="paragraph-list">Excellent planning, organizational, and time management skills</div>
    //             <div className="paragraph-list">Experience leading and developing top performing teams</div>
    //             <div className="paragraph-list">A history of leading and supporting successful project</div>
    //         </>
    //     ),
    //     location: `/careers-open?aW5kZXg=0`,
    //     data: {
    //         location: "Belgrade",
    //         employmentType: "Full-time",
    //         jobDescription: "Drivosity is looking for a Business Analyst and the incumbent will be responsible for:",
    //         shortPoints: (
    //             <>
    //                 <li>Familiarity with the Drivosity Business Model, Platform, Market and Customers</li>
    //                 <li>Development of requirement documentation that will translate business needs into requirements that can be understood by the solution development team</li>
    //                 <li>Staying current with customer needs utilising formal and informal written communication methods to communicate updates and findings</li>
    //             </>
    //         ),
    //     },
    // },
    // {
    //     id: 102,
    //     title: `Data Scientist`,
    //     content: (
    //         <>
    //             <div className="paragraph">Technology and the ability to gather and manipulate vast quantities of data have fundamentally altered the way that company’s collect, process, analyze, and disseminate information. Business leaders worldwide are struggling to comprehend the advantages of this collected information to improve insights and decision making. The breadth of the data problem, as seen from an IOT edge perspective, is striking: Drivosity needs to process, interpret and disseminate data collected by an increasingly large and diverse array of sources and sensors—each capturing volumes of data on orders of a far larger magnitude than just a few years ago.</div>
    //             <div className="paragraph"></div>
    //             <div className="paragraph">Drivosity is looking for a Data Scientist. Successful candidates will be responsible for:</div>
    //             <div className="paragraph-list">Experienced commercial side data science with “hands-on” and theoretical approaches</div>
    //             <div className="paragraph-list">Mine and analyze data from company databases to drive insights and improvement of product offerings</div>
    //             <div className="paragraph-list">Use predictive modeling to increase and optimize customer experiences, revenue generation, service targeting and other business outcomes.</div>
    //             <div className="paragraph">In this role, you should be detail-oriented and possess excellent theoretical and practical data science skills. Ultimately, you will ensure that Drivosity’s product suite stays aligned to growing customer needs and evolving big data market landscapes.</div>
    //             <div className="drv-title pt-8">Responsibilities</div>
    //             <div className="paragraph-list">Apply theories, conduct experiments, and undertake studies and tests using advanced data mining and modeling techniques</div>
    //             <div className="paragraph-list">Interpret data and trends and present material using compelling data visualizations</div>
    //             <div className="paragraph-list">Find creative approaches and bring new perspectives on available data to provide insight into performance across business sectors</div>
    //             <div className="paragraph-list">Work with the machine learning group to develop complimentary big data sets</div>
    //             <div className="paragraph-list">Advise on best practices and approaches using best available toolsets, while suggesting and helping to implement new tools</div>
    //             <div className="paragraph-list">Collaborate with internal and external stakeholders to identify opportunities to advance machine learning and artificial intelligence</div>
    //             <div className="paragraph-list">Coordinate with different functional teams to implement models and monitor outcomes.</div>
    //             <div className="paragraph-list">Develop processes and tools to monitor and analyze model performance and data accuracy</div>
    //             <div className="paragraph-list">Work with stakeholders throughout the organization to identify opportunities for leveraging company data to drive business solutions.</div>
    //             <div className="paragraph-list">Assess the effectiveness and accuracy of new data sources and data gathering techniques</div>
    //             <div className="paragraph-list">Develop custom data models and algorithms to apply to data sets</div>
    //             <div className="paragraph-list">Work with internal and external customers to analyze data lake needs and align to product roadmap and Drivosity’s customer goals</div>
    //             <div className="drv-title pt-8">Requirements</div>
    //             <div className="paragraph-list">Experience using statistical computer languages (R, Python, SLQ, etc.) to manipulate data and draw insights from large data sets.</div>
    //             <div className="paragraph-list">Experience working with and creating data architectures.</div>
    //             <div className="paragraph-list">Knowledge of a variety of machine learning techniques (clustering, decision tree learning, artificial neural networks, etc.) and their real-world advantages/drawbacks.</div>
    //             <div className="paragraph-list">Knowledge of advanced statistical techniques and concepts (regression, properties of distributions, statistical tests and proper usage, etc.) and experience with applications.</div>
    //             <div className="paragraph-list">Excellent written and verbal communication skills for coordinating across teams.</div>
    //             <div className="paragraph-list">A drive to learn and master new technologies and techniques.</div>
    //             <div className="paragraph-list">5-7 years of experience manipulating data sets and building statistical models, has a Master’s or PHD in Statistics, Mathematics, Computer Science or another quantitative field, and is familiar with the following software/tools:</div>
    //             <div className="paragraph-list">Coding knowledge and experience with several languages: C, C++, Java, JavaScript, etc.</div>
    //             <div className="paragraph-list">Knowledge and experience in statistical and data mining techniques: GLM/Regression, Random Forest, Boosting, Trees and text mining</div>
    //             <div className="paragraph-list">Experience querying databases and using statistical computer languages: R, Python and SLQ</div>
    //             <div className="paragraph-list">Experience using Sage Maker and Redshift web services</div>
    //             <div className="paragraph-list">Experience creating and using advanced machine learning algorithms and statistics: regression, simulation, scenario analysis, modeling, clustering, decision trees and neural networks</div>
    //             <div className="paragraph-list">Ability to convert multiple, sometimes conflicting data sets into both short- and long-term business requirements for Product delivery</div>
    //             <div className="paragraph-list">Familiarity with Agile frameworks</div>
    //             <div className="paragraph-list">Strong attention to detail</div>
    //             <div className="paragraph-list">Strong organizational skills</div>
    //             <div className="paragraph-list">Analytical mind and problem-solving aptitude</div>
    //             <div className="paragraph-list">Strong ability to effectively manage time to complete assigned tasks</div>
    //         </>
    //     ),
    //     location: `/careers-open?aW5kZXg=1`,
    //     data: {
    //         location: "Belgrade",
    //         employmentType: "Full-time",
    //         jobDescription: "Drivosity is looking for a Data Scientist. Successful candidates will be responsible for:",
    //         shortPoints: (
    //             <>
    //                 <li>Experienced commercial side data science with “hands-on” and theoretical approaches</li>
    //                 <li>Mine and analyze data from company databases to drive insights and improvement of product offerings</li>
    //                 <li>Use predictive modeling to increase and optimize customer experiences, revenue generation, service targeting and other business outcomes</li>
    //             </>
    //         ),
    //     },
    // },
];

export {CareersData, JobsData}
