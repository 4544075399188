import React from "react";

const DrivosityHTHData = [
    {
        id: 7,
        title: `DRIVOSITY AND HTH`,
        content: 'A partnership built to drive increased sales and maximize operations',
        location: `/drivosity-and-hth`,
        data: null,
    },
    {
        id: 7,
        title: `DRIVOSITY AND HTH`,
        content: (
            <>
                <div className='mb-7'>
                    Founded in 2015, Drivosity set out to be the leading telematics provider for last mile delivery, utilizing the power of GPS to positively influence safety and productivity. HTH, the leading manufacturer for car toppers in delivery, embraced the partnership as something that would revolutionize the delivery industry.
                </div>

                <div className='mb-7'>
                    Revolutionize it did. The companies found a natural alignment with their goals: increasing sales for delivery businesses with the great exposure car toppers provide, while making the driver as safe and productive as possible. The GPS inside the topper gathers analytics and calculates the DriveScore® to impact safety, while the battery that powers the topper also powers the GPS.
                </div>

                <div className='mb-7'>
                    It is a match made in delivery heaven, and HTH continues to this day to be one of Drivosity’s closest partners, as well as its manufacturing powerhouse.
                </div>
            </>
        ),
        location: `/drivosity-and-hth`,
        data: null,
    },
    {
        id: 7,
        title: ``,
        content: 'Want to learn more about boosting your business through HTH?',
        location: `/drivosity-and-hth`,
        data: null,
    },
    {
        id: 7,
        title: ``,
        content: 'Want to learn more?',
        location: `/drivosity-and-hth`,
        data: null,
    },
];

export {DrivosityHTHData};
