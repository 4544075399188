import * as React from "react";

const ForBusinessData = [
    {
        id: 5,
        title: `FOR BUSINESS`,
        content: 'Increase overall business operations',
        location: `/for-business`,
        data: null,
    },
    {
        id: 5,
        title: `IMMEDIATE REDUCTION IN AUTO ACCIDENTS AND CLAIMS`,
        content: 'Empowering drivers with quantitative scores on their performance can yield immediate results. By the end of the first day of use, most customers see average DriveScore® improvements that can reduce the risk of an accident by as much as 450 times. After installing Drivosity, business owners report reductions in claims costs by as much as 50-90%.',
        location: `/for-business`,
        data: null,
    },
    {
        id: 5,
        title: `INSURANCE SAVINGS`,
        content: (
            <>
                The Drivosity team understands the increasing challenges businesses face as they acquire commercial auto insurance and workers’ compensation coverage for their delivery fleet. Increased costs of insurance claims and less competition in the insurance market are driving costs up, even for those with decent loss runs. Drivosity customers can receive <span className='drv-link'>premium reductions on auto insurance and workers’ compensation by as much as 50%</span>, based on Drivosity’s proven results in loss prevention.
            </>
        ),location: `/for-business`,
        data: null,
    },
    {
        id: 5,
        title: `INSURANCE SAVINGS`,
        content: (
            <>
                In one case study, a multi-unit food delivery franchisee business (over 50 units) realized a <span className='drv-link'>two-year savings of $550,000</span>. After a tough year with two 7-figure claims, only a single insurance carrier was willing to provide them with a quote for coverage. Desperate to protect profitability, they implemented Drivosity and conducted store tours with six carriers to share their new culture of safety built around Drivosity analytics. The increased confidence among the carriers led them to competitively quote, and drove down insurance rates by $220,000 in the first year. At renewal, the franchisee continued to see additional premiums savings of $330,000 in spite of the rest of the delivery industry experiencing a 10% increase. Many Drivosity customers, like this franchisee, have found that Drivosity often pays for itself in just the insurance savings alone.
            </>
        ),location: `/for-business`,
        data: null,
    },
    {
        id: 5,
        title: `UNIQUE TRACKING COMPONENTS`,
        content: (
            <>
                Drivosity provides various device options to fit different business applications,  so that drivers do not need to use their personal cell phones for tracking:
            </>
        ),location: `/for-business`,
        data: null,
    },
    {
        id: 5,
        title: `Contact Drivosity`,
        content: 'At sales@drivosity.com to find out how your organization can leverage our solutions to empower delivery innovation, safety and productivity.',
        location: `/for-business`,
        data: null,
    },
    {
        id: 5,
        title: ``,
        content: `
            ADDITIONAL BENEFITS
        `,
        location: `/for-business`,
        data: null,
    },
];

const ForBusinessBenefitsCollapse = [
    {
        id: 5,
        title: `Increased Profit`,
        content: (
            <>Drivosity’s To-the-Door Dashboard provides insight into every stage of the delivery, from the point a customer submits an order, to the production time, driver prep time, drive time and to-the-door time with the customer. Consequently, more deliveries per hour translates into more sales, improved customer satisfaction and increased loyalty.</>
        ),
        location: `/for-business`,
        data: null
    },
    {
        id: 5,
        title: `Managing Labor Cost`,
        content: (
            <>With Drivosity’s live GPS tracking tool, teams know exactly where drivers are, how many drivers are out doing deliveries and how many drivers are in the store. This helps managers ensure there are enough drivers to meet customers’ demands at all times, while helping to keep labor costs down. Also, logout reports provide support for wage and hour compliance by showing store time vs. drive time, GPS miles driven, and time on the clock.</>
        ),
        location: `/for-business`,
        data: null
    },
    {
        id: 5,
        title: `Medical Emergencies`,
        content: (
            <>Knowing where your drivers are at all times can be critical when medical emergencies arise. Drivosity’s Driver Inspire Display flags drivers who have been stopped for extended periods of time and the Find Me location sharing feature has helped save the lives of drivers who became incapable of calling for help because of medical conditions or armed robbery.</>
        ),
        location: `/for-business`,
        data: null
    },
    {
        id: 5,
        title: `Vehicle And Property Recovery`,
        content: (
            <>Criminals often view delivery drivers as easy targets for vehicle theft and robbery. Drivosity’s GPS technology has helped to recover stolen toppers and vehicles, and in one case even helped bust a chop shop.</>
        ),
        location: `/for-business`,
        data: null
    },
    {
        id: 5,
        title: `Peace Of Mind`,
        content: (
            <>Knowing drivers are out there doing the right thing by driving safely is priceless. Business owners regularly report peace of mind and sleeping better at night as a significant, less-monetizable benefit of Drivosity. Reduced auto incidents translate into less late night calls where a driver has been involved in an auto accident that resulted in substantial property damage, or worse, personal injuries.</>
        ),
        location: `/for-business`,
        data: null
    }
];

const ForBusinessComponentsCollapse = [
    {
        id: 5,
        title: `HTH Car Toppers`,
        content: (
            <>HTH Toppers Powered by Drivosity GPS. In partnership with HTH Inc., the leading car sign maker in the United States, Drivosity can be integrated into the car toppers that you’re already using.  GPS-enabled Car Toppers are cordless, battery operated, and include a photo sensor to auto-illuminate in dim lighting.</>
        ),
        location: `/for-business`,
        data: null
    },
    {
        id: 5,
        title: `Stand Alone`,
        content: (
            <>Drivosity Stand Alone -We offer a stand alone tracking device to customers in need of a non-branded, low profile GPS tracking option.  This device is magnetic and can be installed inside or outside the vehicle. The Drivosity Stand Alone option is also frequently used for high crime areas or for vehicles that can’t support car toppers. Because of its versatile and compact design, our stand alone option is also used for bike deliveries.</>
        ),
        location: `/for-business`,
        data: null
    },
    {
        id: 5,
        title: `Hardwire Into Vehicle`,
        content: (
            <>Hardwiring into company-owned vehicles is a convenient and discreet way to  implement GPS tracking.</>
        ),
        location: `/for-business`,
        data: null
    }
]

export {ForBusinessData, ForBusinessBenefitsCollapse, ForBusinessComponentsCollapse};
