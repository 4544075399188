const RequestDemoData = [
    {
        id: 14,
        title: `REQUEST A DEMO`,
        content: '',
        location: `/request-demo`,
        data: null,
    },
    {
        id: 14,
        title: ``,
        content: `
            REQUEST DEMO,
            SAFE & SECURE,
            FAST DELIVERY,
            24/7 SUPPORT
        `,
        location: `/request-demo`,
        data: null,
    },
];

export {RequestDemoData};
