import styled from "styled-components";

export const NewsCardContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
`;

export const NewsCardContainer = styled.div`
  margin-bottom: 30px;
  border-radius: 1px;
  border: 1px solid #e6e6e6;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);
    border-color: transparent;
  }
`;

export const NewsCardImg = styled.div`
  position: relative;
  width: 350px;
  @media screen and (max-width : 968px){
    width: 100%;
  }
`;

export const NewsCardTitle = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    color: #545454;
    &:hover{
      color: #0e6faa;
    }
`;

export const NewsCardDescription = styled.div`
  font-size: 16px;
  color: #808080;
`;

export const NewsCardDate = styled.div`
  position: absolute;
  color: #fff;
  top: 0;
  right: 0;
  height: 75px;
  width: 75px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NewsImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin: auto;
`;
