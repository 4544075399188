import styled from "styled-components";

export const PaginationRow = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

export const PaginationNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    /* border: 2px solid var(--primary-color); */
    border-radius: 50%;
    margin: 0px 2px;
    &:hover{
        background-color: var(--primary-color);
        color: #fff;
    }
    &.active{
        border: 2px solid #d9d9d9;
    }
`;

export const PaginationPrev = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    border-radius: 100px;
    margin: 0px 2px;
    padding: 0 20px;
    &:hover{
        background-color: var(--primary-color);
        color: #fff;
    }
`;

export const PaginationNext = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    border-radius: 100px;
    margin: 0px 2px;
    padding: 0 20px;
    &:hover{
        background-color: var(--primary-color);
        color: #fff;
    }
`;
